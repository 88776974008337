.cursor {
    background-color: #111;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 10000;
    box-shadow: 0px 1px 60px 0px rgba(238, 234, 234, 0.75);
-webkit-box-shadow: 0px 1px 60px 0px rgba(238, 234, 234, 0.75);
-moz-box-shadow: 0px 1px 60px 0px rgba(238, 234, 234, 0.75);
  }

  